<template>
    <div class="car-page">
        <div class="title">
            电动车业务
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="jumpToAddCar()">添加电动车</el-button>
            <div style="display: flex;">
               <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="carList">
                <el-table-column prop="car_name" align="center" label="标题"  width="180">
                </el-table-column>
                <el-table-column prop="car_desc" align="center" label="副标题" width="180">
                </el-table-column>
                <el-table-column align="center" label="价格（元）" width="180">
                     <template slot-scope="scope">
                        {{(scope.row.unit_price/100).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="update_user_name" align="center" label="最后更新者">
                    <template slot-scope="scope">
                        {{scope.row.update_user_name || scope.row.create_user_name}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <span>
                         <el-button type="text" size="small" style="margin-right: 10px" @click="jumpToAddCar(scope.row)">编辑</el-button>
                         </span>
                         <span>
                             <el-popconfirm
                                confirmButtonText='确定'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteCar(scope.row.id)"
                                title="确定删除该电动车吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getCarList">
            </el-pagination>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Car',
    data () {
        return {
            // 电动车列表
            carList: [],
            page: 1,
            size: 10,
            total: 0,

            querySelect: '',
            visible: false
        }
    },
    mounted () {
        this.getCarList()
    },
    methods: {
        // 获取电动车列表
        getCarList () {
            this.axios.post('/car/get/list', {
                page: this.page,
                size: this.size,
                query_select: this.querySelect
            }).then((res) => {
                this.carList = res.data[0].list
                this.total = res.data[0].count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getCarList()
        },

        // 跳转到编辑或者新增界面
        jumpToAddCar (editCarData) {
            if (editCarData) {
                localStorage.setItem('carEditData', JSON.stringify(editCarData))
            }
            this.$router.push('car/add/' + !!editCarData)
        },

        // 删除
        deleteCar (editCarId) {
            this.axios.post('/car/delete', {
                car_id: editCarId
            }).then((res) => {
                if (res) {
                    this.getCarList()
                    this.$message.success('删除成功')
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .car-page{
        .title{
            padding: 14px;
            margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }
    }
</style>
